import { Link } from 'react-router-dom';
import { useDashboardGetTeacherNotificationData, useNotificationLogFindAll } from 'src/generated/endpoint';
import { NotificationLog } from 'src/generated/model';
import { makeDateToString } from 'src/util/time';
import { Section } from '../common';

export const NotificationModal = () => {
  const { data: dashboardItem } = useDashboardGetTeacherNotificationData();
  const { data } = useNotificationLogFindAll();

  const notconfirmedUrls = ([] as any[])
    .concat(dashboardItem?.unapprovedOutings?.map((el) => `/student/activityv3/${el.id}`) || [])
    .concat(dashboardItem?.unapprovedAbsents?.map((el) => `/student/board/${el.id}`) || [])
    .concat(dashboardItem?.unapprovedFieldTrips?.map((el) => `/student/newsletter/${el.id}`) || [])
    .concat(dashboardItem?.unapprovedFieldTripResults?.map((el) => `/student/notice/${el.id}`) || []);

  return (
    <div className="h-full select-none overflow-y-auto rounded-lg border border-gray-300 bg-[#FFF8F2]">
      <Section className="space-y-2">
        {data?.map((log: NotificationLog) => (
          <Link key={log.id} to={log.url}>
            <div className="flex cursor-pointer items-center justify-between space-x-2 rounded-xl border border-gray-300 bg-white px-4 py-2">
              <div className="w-full overflow-hidden">
                <div className="flex  w-full items-center space-x-1">
                  <div className="max-w-full truncate text-sm font-semibold">{log.title}</div>
                  {notconfirmedUrls.includes(log.url) && (
                    <small className="inline-block h-4 w-4 rounded-full bg-red-500 text-center text-xs font-light text-white">
                      N
                    </small>
                  )}
                </div>

                <div className="truncate text-12 text-gray-500"> {log.body}</div>
              </div>
              <div className="min-w-max text-12 text-gray-500"> {makeDateToString(log.createdAt)}</div>
            </div>
          </Link>
        ))}
      </Section>
    </div>
  );
};
