import { t } from 'i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BackButton, Section, TopNavbar } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { ParentInfoCard } from 'src/components/MyInfo/ParentInfoCard';
import { useStudentKlassHistory } from 'src/container/student-klass-history';
import { Role } from 'src/generated/model';
import { isUpdateMeState, meState } from 'src/store';
import { MyInfoUpdatePage } from './MyInfoUpdatePage';

export function MyInfoPage() {
  const me = useRecoilValue(meState);
  const [isUpdateMe, setIsUpdateMe] = useRecoilState(isUpdateMeState);

  const { klassHistoryList } = useStudentKlassHistory();

  const isNotParent = me?.role !== Role.PARENT;

  if (!me) return null;

  if (isUpdateMe) {
    return <MyInfoUpdatePage me={me} setIsUpdateMe={setIsUpdateMe} />;
  }

  return (
    <div>
      <TopNavbar
        title="내 정보"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <Section>
        <div>
          <div className="text-lg font-bold text-gray-800">이름</div>
          <div className="text-grey-2">{me?.name}</div>
        </div>
        {me?.role === Role.USER && (
          <div>
            <div className="text-lg font-bold text-gray-800">{t(`Custom.SID${me?.schoolId}.nickName`, '별명')}</div>
            <div className="text-grey-2">{me?.nickName}</div>
          </div>
        )}
        <div>
          <div className="text-lg font-bold text-gray-800">이메일</div>
          <div className="text-grey-2">{me?.email}</div>
        </div>
        <div>
          <div className="text-lg font-bold text-gray-800">전화번호</div>
          <div className="text-grey-2">{me?.phone}</div>
        </div>

        {isNotParent && (
          <>
            <div>
              <div className="text-lg font-bold text-gray-800">학교</div>
              <div className="text-grey-2">{me?.school?.name}</div>
            </div>
            <div>
              <div className="text-lg font-bold text-gray-800">반/번호</div>
              <div className="text-grey-2">
                {klassHistoryList
                  ?.sort((a, b) => +(a?.group?.year || 0) - +(b?.group?.year || 0))
                  .map((klass) => (
                    <>
                      {klass?.group?.year} {me?.school?.name} {klass?.group?.name} {klass?.studentNumber}번
                      <br />
                    </>
                  ))}
              </div>
            </div>
          </>
        )}
        {isNotParent && (
          <>
            <div>
              <div className="text-lg font-bold text-gray-800">생년월일</div>
              <div className="text-grey-2">{me?.birthDate}</div>
            </div>
            {/* <div>
              <div className="text-lg font-bold text-gray-800">희망진로</div>
              <div className="text-grey-2">{me?.hopePath}</div>
            </div>
            <div>
              <div className="text-lg font-bold text-gray-800">희망학과</div>
              <div className="text-grey-2">{me?.hopeMajor}</div>
            </div> */}
          </>
        )}
        <br />

        <Button.lg children="수정하기" onClick={() => setIsUpdateMe(true)} className="filled-primary" />

        <ParentInfoCard me={me} isNotParent={isNotParent} />

        <div className="h-24 w-full" />
      </Section>
    </div>
  );
}
