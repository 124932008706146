import { ReactComponent as Activity } from 'src/assets/icons/activity.svg';
import { ReactComponent as Avatar } from 'src/assets/icons/avatar.svg';
import { ReactComponent as Back } from 'src/assets/icons/back.svg';
import { ReactComponent as Bell } from 'src/assets/icons/bell.svg';
import { ReactComponent as Check } from 'src/assets/icons/check.svg';
import { ReactComponent as ChevronDown } from 'src/assets/icons/chevron-down.svg';
import { ReactComponent as ChevronLeft } from 'src/assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'src/assets/icons/chevron-right.svg';
import { ReactComponent as ChevronUp } from 'src/assets/icons/chevron-up.svg';
import { ReactComponent as Close } from 'src/assets/icons/close.svg';
import { ReactComponent as Home } from 'src/assets/icons/home.svg';
import { ReactComponent as Info } from 'src/assets/icons/info.svg';
import { ReactComponent as MoreHorizontal } from 'src/assets/icons/more-horizontal.svg';
import { ReactComponent as MoreVertical } from 'src/assets/icons/more-vertical.svg';
import { ReactComponent as Notice } from 'src/assets/icons/notice.svg';
import { ReactComponent as Planner } from 'src/assets/icons/planner.svg';
import { ReactComponent as Plus } from 'src/assets/icons/plus.svg';
import { ReactComponent as Search } from 'src/assets/icons/search.svg';
import { ReactComponent as Send } from 'src/assets/icons/send.svg';
import { ReactComponent as AIRobot } from 'src/assets/svg/ai_robot.svg';
import { ReactComponent as ArrowOrder } from 'src/assets/svg/arrow-order.svg';
import { ReactComponent as FillArrow } from 'src/assets/svg/arrow_fill.svg';
import { ReactComponent as CloseFillGray } from 'src/assets/svg/closeGray.svg';
import { ReactComponent as Download } from 'src/assets/svg/download.svg';
import { ReactComponent as Fail } from 'src/assets/svg/fail.svg';
import { ReactComponent as Hide } from 'src/assets/svg/hide.svg';
import { ReactComponent as Loader } from 'src/assets/svg/loader.svg';
import { ReactComponent as LoaderOrange } from 'src/assets/svg/loader_orange.svg';
import { ReactComponent as LoaderOrangePC } from 'src/assets/svg/loader_orange_pc.svg';
import { ReactComponent as Logo } from 'src/assets/svg/logo.svg';
import { ReactComponent as AI } from 'src/assets/svg/logo_ai.svg';
import { ReactComponent as RightFillArrow } from 'src/assets/svg/mypage-right-arrow.svg';
import { ReactComponent as Pending } from 'src/assets/svg/pending.svg';
import { ReactComponent as Refresh } from 'src/assets/svg/refresh.svg';
import { ReactComponent as Reply } from 'src/assets/svg/reply.svg';
import { ReactComponent as Show } from 'src/assets/svg/show.svg';
import { ReactComponent as Success } from 'src/assets/svg/success.svg';

export const Icon = {
  Download,
  Activity,
  ArrowOrder,
  Avatar,
  Back,
  Bell,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Close,
  Home,
  MoreHorizontal,
  MoreVertical,
  Notice,
  Planner,
  Search,
  Send,
  Info,
  Refresh,
  Reply,
  Plus,
  FillArrow,
  RightFillArrow,
  Logo,
  AI,
  AIRobot,
  Loader,
  Success,
  Fail,
  Pending,
  Hide,
  Show,
  CloseFillGray,
  LoaderOrange,
  LoaderOrangePC,
};
